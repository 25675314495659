.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Icon-Accepted {
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(-50px);
  }
  15% {
    transform: translateY(-55px);
  }
  20% {
    transform: translateY(-50px);
  }
  65% {
    transform: translateY(-50px);
  }
  70% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-5px);
  }
  80% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes Icon-Rejected {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-35deg);
  }
  40% {
    transform: rotate(25deg);
  }
  60% {
    transform: rotate(-20deg);
  }
  80% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes Icon-Accepted-Color {
  from {
    color: black;
  }
  to {
    color: aqua;
  }
}

@keyframes Icon-Rejected-Color {
  from {
    color: black;
  }
  to {
    color: firebrick;
  }
}

.accepted-animation {
  animation: Icon-Accepted infinite 2s, Icon-Accepted-Color .5s ;
  animation-fill-mode: forwards;
  /* animation: Icon-Accepted-Color .5s; */
}

.rejected-animation {
  animation: Icon-Rejected infinite 1.25s, Icon-Rejected-Color .5s;
  animation-fill-mode: forwards;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #34db93; /* Blue */
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}